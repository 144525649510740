<template>
    <div class="p-grid">
        <div class="p-col-12 p-md-12">
            <div class="card">
                <h5>推薦盒組</h5>
                <Fieldset
                    :toggleable="true"
                    :collapsed="true"
                    v-for="(theme, index) in recommendSets"
                    :key="index"
                    :legend="theme.name"
                    class="p-mb-4"
                >
                    <div class="p-grid">
                        <Card
                            class="p-col-12 p-md-4 p-mb-4"
                            v-for="(set, setidx) in theme.sets"
                            :key="setidx"
                        >
                            <template #header>
                                <img :alt="set.set_num" :src="set.photo" />
                            </template>
                            <template #title> {{ set.set_num }} </template>
                            <template #content>
                                <div>
                                    <b>{{ set.year }}</b>
                                </div>
                                <div>{{ set.name }}</div>
                                <div>
                                    {{ set.parts.toLocaleString() }} parts
                                </div>
                            </template>
                            <!-- <template #footer>
                                <Button
                                    label="Lego"
                                    class="
                                        p-button-outlined
                                        p-button-warning
                                        p-mr-2
                                        p-mb-2
                                    "
                                />
                                <Button
                                    label="Rebrickable"
                                    class="
                                        p-button-outlined
                                        p-button-info
                                        p-mr-2
                                        p-mb-2
                                    "
                                />
                            </template> -->
                        </Card>
                    </div>
                </Fieldset>
            </div>
        </div>
    </div>
</template>

<script>
import SetService from "../service/SetService";

export default {
    data() {
        return {
            recommendSets: [],
        };
    },
    SetService: null,
    created() {
        this.SetService = new SetService();
    },
    mounted() {
        this.SetService.getRecommend().then((data) => {
            this.recommendSets = data.data;
        });
    },
    beforeUpdate() {},
    methods: {},
};
</script>

<style scoped lang="scss">
</style>
